// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    type: "staging",
    api: {
        url: 'https://api.liveco.staging.superdev.fr',
        domain: 'liveco.staging.superdev.fr'
    },
    slug: 'liveco',
    transport: 'socket',
    cfURL: 'https://cloudflow.liveco.staging.superdev.fr',
    kioskURL: 'http://192.168.2.173:9090',
    // kioskURL:"https://liveco.diadem-group.com/portal.cgi",
    baseRouteLogin: '/portal.cgi/PP_FILE_STORE/Public/Form/Login/login.html',
    baseRoute: 'portal.cgi/PP_FILE_STORE/LiveCo/',
    isLocal: false,

    easydiadem: {
        pdf_repository: {
            cf_upload_path: "/portal.cgi?asset=upload_file&url=cloudflow%3A%2F%2FPP_FILE_STORE%2FPublic%2FPDF%2F&overwrite=true",
            file_upload_api: "https://tmpfiles.org/api/v1/upload",
            pathSearchProject: "custom.easydiadem.pdf_repository.pr_id",
            collectionPath: "ED_proofreadings"
        },
        text_correction: {
            cf_upload_path: "/portal.cgi?asset=upload_file&url=cloudflow%3A%2F%2FPP_FILE_STORE%2FPublic%2FCORRECTIONS%2F&overwrite=true",
        }
    },
    whitepapers: {
        livecore: "LIVECO_CORE",
        proofreading: "PR_Formulaire",
        projectCreation: "PC_Formulaire",
        printers: "PRINTER_Formulaire",
        users: "USER_Formulaire",
        constances: "SD_CONSTANCES",
        colorBooks: "COLORBOOKS_Formulaire",
        easydiadem: {
            core: "ED_CORE_BUSINESS",
            ED_Depot_PDF: "ED_Depot_PDF",
            ED_Text_Correction: "ED_Text_Correction"
        },
        fetchL2pubBdd: "SD_FETCH_L2PUB_BDD",
        proofreadingIntern: "SF_Relecture_Interne",
        diademCartouche: "Diadem_Preview_Cartouche"

    },
    regex_xraid_server: /^\/\/XRAID-SERVEUR/,
    regex_srv_data_01: /^\/\/SRV-DATA-01/,
    volumes_mac: "/Volumes",
    cloudflow_url_base: "cloudflow://Production",
    archives: {
        windows: "\\\\SRV-DATA-02\\Production\\Development\\CLOUDFLOW-DEV\\Filestores\\DEV_ARCHIVES\\",
        mac: "/Volumes/Production/Development/CLOUDFLOW-DEV/Filestores/DEV_ARCHIVES/"
    },
    proofscope_placeholder: "/portal.cgi?proofscope&url=cloudflow%3a%2f%2fPP_FILE_STORE%2fPublic%2fForm%2fimg%2fdiadem-logo.ai&view_id=8bc078c6-fd0d-4707-8011-52b51f5dc6ad&signature=15911ebe2d8e542dc2b9797a6aef8c87"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
