import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { IChildProject } from '../models/project.model';
import { ProjectService } from './project.service';
import { IProofReading } from '../models/proofreading.model';


@Injectable({
    providedIn: 'root'
})
export class DashboardHistoryService {

    private _dashboardRecents: IChildProject[] = [];
    get dashboardRecents(): IChildProject[] {
        const recentSorted = [];
        for (const project of this._dashboardRecents) {
            if (project && project.custom.proofreadings && project.custom.proofreadings.length > 0) {
                project.custom.proofreadings = project.custom.proofreadings.sort((a: IProofReading, b: IProofReading) => a.flow.localeCompare(b.flow));
            }

            recentSorted.push(project);
        }
        return recentSorted;
    }
    readonly limit: number = 10;

    constructor(
        protected s_projects: ProjectService,
    ) {
        this.refreshProjectsCards();
    }

    updateProjectCard(project: IChildProject): void {
        const item = _.cloneDeep(project);
        this._dashboardRecents.unshift(item);
        this._dashboardRecents = _.uniqBy(this._dashboardRecents, (item) => item?._id);
        if (this._dashboardRecents.length > this.limit) {
            this._dashboardRecents.splice(this.limit, this._dashboardRecents.length - this.limit);
        }
        localStorage.setItem('dashboard_history_v2', JSON.stringify(this._dashboardRecents.map(p => p.identifier)));
    }

    async refreshProjectsCards() {
        const identifiers = JSON.parse(localStorage.getItem('dashboard_history_v2') || '[]');
        if (identifiers.length === 0) {
            return;
        }
        const projects = await this.s_projects.find({ identifier: { $in: identifiers } }, { $callbacks: { stateless: true }, $triggerEntityList: false, $disablePending: true });
        for (let project of projects) {
            const recent: IChildProject | undefined = this._dashboardRecents.find(p => p._id === project._id);
            if (recent) {
                _.assign(recent, project);
            } else {
                this._dashboardRecents.push(project);
            }
        }
    }
}

