import { BaseModel } from './base.model';
import { FlowType, IProofReading } from './proofreading.model';
import { ExternStateName, InternStateName } from './states.model';
import { IProofreadingMember } from './members.model';

export interface IHistory {
    [numRelecture: string]: any[] | any;
}

export interface IPRHistoryItem {
    proofreadingID: string;
    project: {
        ID: string,
        identifier: string,
    },
    type: 'intern' | 'extern' | 'approval';
    flow: FlowType;
    proofreading: IProofReading;
    history: IHistory;
    getLastAssessment(state_slug?: InternStateName | ExternStateName): PRApprovalHistoryAssessment | PRExternHistoryAssessment | PRInternHistoryAssessment | null;
}



export interface IPRInternHistoryItem extends IPRHistoryItem {
    history: {
        [numRelecture: string]: IPRInternHistoryVersion[];
    };
}

export interface IPRApprovalHistoryItem extends IPRHistoryItem {
    history: {
        [numRelecture: string]: IPRApprovalHistoryRecord;
    };
}

export interface IPRExternHistoryItem extends IPRHistoryItem {
    history: {
        [numRelecture: string]: IPRExternHistoryRecord[];
    };
}

export type PRInternHistoryAssessment = 'approved' | 'rejected' | null;
export interface IPRInternHistoryStates {
    from: InternStateName;
    to: InternStateName;
    user: string;
    date: string;
    assessment: PRInternHistoryAssessment;
}

export type PRExternHistoryAssessment = 'approved' | 'rejected' | null;
export interface IPRExternHistoryRecord {
    relecture: number,
    labels: {
        relecture: string,
    },
    from: InternStateName;
    to: InternStateName;
    user: string;
    date: string;
    url: string | null;
    file_url: string | null;
    assessment: PRExternHistoryAssessment,
}

export type PRApprovalHistoryAssessment = 'reject' | 'accept';
export interface IPRApprovalHistoryRecord {
    relecture: number,
    labels: {
        relecture: string,
    },
    members: IProofreadingMember[],
    assessment: PRApprovalHistoryAssessment,
    url: string | null;
    file_url: string | null;
    date: string,
}

export interface IPRInternHistoryVersion {
    date: string;
    states: IPRInternHistoryStates[];
    url: string | null;
    file_url: string | null;
    version: number,
    relecture: number,
    labels: {
        relecture: string,
        version: string,
    },
}


export class PRHistoryItem extends BaseModel<IPRHistoryItem> implements IPRHistoryItem {
    proofreadingID: string;
    project: {
        ID: string,
        identifier: string,
    };
    type: 'intern' | 'extern' | 'approval';
    flow: FlowType;
    history: IHistory;
    proofreading: IProofReading;

    getLastAssessment(state_slug?: InternStateName | ExternStateName): PRApprovalHistoryAssessment | PRExternHistoryAssessment | PRInternHistoryAssessment | null {
        return null;
    }
}

export class PRInternHistoryItem extends PRHistoryItem implements IPRInternHistoryItem {
    history: {
        [numRelecture: string]: IPRInternHistoryVersion[];
    };

    getLastRelecture() {
        const lastIndex = Object.keys(this.history)[Object.keys(this.history).length - 1];
        return this.history[lastIndex];
    }

    getLastVersion() {
        const lastIndex = Object.keys(this.history)[Object.keys(this.history).length - 1];
        return this.history[lastIndex][this.history[lastIndex].length - 1];
    }

    getLastStates() {
        const lastIndex = Object.keys(this.history)[Object.keys(this.history).length - 1];
        return this.history[lastIndex][this.history[lastIndex].length - 1].states;
    }

    getLastAssessment(state_slug?: InternStateName | ExternStateName) {
        const lastIndex = Object.keys(this.history)[Object.keys(this.history).length - 1];
        if (!this.history[lastIndex][this.history[lastIndex].length - 1]) {
            return null;
        }
        const lastState = this.history[lastIndex][this.history[lastIndex].length - 1].states;
        if (state_slug) {
            const state = lastState.find(item => item.from === state_slug);
            if (!state) {
                return null;
            }
            return state.assessment;
        }
        return lastState[0].assessment;
    }
}

export class PRApprovalHistoryItem extends PRHistoryItem implements IPRApprovalHistoryItem {
    history: {
        [numRelecture: string]: IPRApprovalHistoryRecord;
    };

    getLastRelecture() {
        const lastIndex = Object.keys(this.history)[Object.keys(this.history).length - 1];
        return this.history[lastIndex];
    }

    getLastAssessment(state_slug?: InternStateName | ExternStateName) {
        const lastIndex = Object.keys(this.history)[Object.keys(this.history).length - 1];
        return this.history[lastIndex].assessment;
    }
}

export class PRExternHistoryItem extends PRHistoryItem implements IPRExternHistoryItem {
    history: {
        [numRelecture: string]: IPRExternHistoryRecord[];
    };

    getLastRelecture() {
        const lastIndex = Object.keys(this.history)[Object.keys(this.history).length - 1];
        console.log(this.history);
        return this.history[lastIndex][this.history[lastIndex].length - 1];
    }

    getLastStates() {
        const lastIndex = Object.keys(this.history)[Object.keys(this.history).length - 1];
        return this.history[lastIndex];
    }

    getLastAssessment(state_slug?: InternStateName | ExternStateName) {
        const lastIndex = Object.keys(this.history)[Object.keys(this.history).length - 1];
        const r = this.history[lastIndex];
        if (state_slug) {
            const state = r.find(item => item.from === state_slug);
            if (!state) {
                return null;
            }
            return state.assessment;
        }
        return r[0].assessment;
    }
}



export class PRHistoryItemFactory {

    public static make(item: IPRHistoryItem): PRInternHistoryItem | PRExternHistoryItem | PRApprovalHistoryItem {
        switch (item.type) {
            case 'intern':
                return new PRInternHistoryItem(item);
            case 'extern':
                return new PRExternHistoryItem(item);
            case 'approval':
                return new PRApprovalHistoryItem(item);
        }
    }
}