import { AfterContentChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { LoaderService } from './services/loader.service';
// import { UrlService } from './services/url.service';
import { NotificationsService } from './services/notifications.service';
import { MatIconRegistry } from '@angular/material/icon';
import { svg_icons } from '../theme/svg-icon';
import { DomSanitizer } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
// @ts-ignore
// // const api = require('@modules/cf-api.js').api;;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterContentChecked {
    title = 'AI-Form';
    previousUrl: string | null = '/';
    currentUrl: string | null = null;

    get loading(): boolean { return this.loader.loading; }
    get loading$(): Observable<boolean> { return this.loader.loading$; }
    get loading_config() { return this.loader.config; }
    get progress() { return this.loader.progress; }


    subscription = new Subscription();

    constructor(
        public router: Router,
        protected loader: LoaderService,
        protected cd: ChangeDetectorRef,
        // private urlService: UrlService,
        protected notif: NotificationsService,
        protected matIconRegistry: MatIconRegistry,
        protected domSanitizer: DomSanitizer,
    ) {
        for (let icon of svg_icons)
            this.matIconRegistry.addSvgIcon(icon[0],
                this.domSanitizer.bypassSecurityTrustResourceUrl(icon[1])
            );
    }

    ngOnInit(): void {
        this.subscription.add(this.loading$.subscribe(() => {
            this.cd.detectChanges();
        }));

        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };

        // // previous url
        // this.router.events.pipe(
        //   filter((event) => event instanceof NavigationEnd)
        // ).subscribe((event: any) => {
        //   console.log('NAVIGATION EVENT', event);
        //   this.router.navigated = false;
        //   this.previousUrl = this.currentUrl;
        //   this.currentUrl = event.url;

        //   // if(_.isString(this.previousUrl)){
        //   //   this.urlService.previousUrl$.next(this.previousUrl);
        //   // }
        // });
    }




    ngAfterContentChecked() {
        this.cd.detectChanges();
    }


    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this.subscription.unsubscribe()
    }
}
